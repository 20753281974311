<section id="video-section" class="bg-gold bg-opacity-10 mb-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6 d-flex align-items-center mb-4 mb-md-0">
        <div class="">
          <h6 class="text-primary fw-bold">للمشاهدة</h6>
          <div class="h4 fw-bold text-dark">ساهم في رسم ملامح مستقبل التعليم</div>
        </div>
      </div>
      <div class="col">
        <div
          class="item"
          [attr.data-src]="videos[0].url"
          [attr.data-sub-html]="videos[0].title"
          (click)="openZoomDialog(videos[0])"
        >
          <div class="video-card">
            <div class="card rounded-0 position-relative thumbnail-container">
              <img
                class="img-fluid contain d-block w-100 thumbnail-image"
                [src]="getThumbnailURL(videos[0].url)"
                [alt]="videos[0].title"
              />
              <img src="assets/images/youtube-icon.png" alt="youtube-icon" class="youtube-icon" />
            </div>
            <h6 class="text-center text-dark fw-bold mb-0 mx-3 w-75 mx-auto">
              {{ videos[0].title }}
            </h6>
          </div>
        </div>
        <div *ngIf="loading" class="d-flex align-items-center justify-content-center bg-light">
          <div class="spinner"></div>
        </div>
      </div>
    </div>
  </div>
</section>
