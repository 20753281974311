<!-- <div class="image-container">
  <img src="{{ assetsURI }}/{{ data.imageId }}" [alt]="data.alt" />
  <button mat-icon-button class="close-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div> -->

<div class="video-container">
  <div class="d-flex justify-content-center align-items-center my-5">
    <youtube-player [videoId]="videoId"></youtube-player>
    <button mat-icon-button class="close-button bg-danger" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
