import {gql} from "apollo-angular";
import {Document} from "../interfaces/landing-page";

export const GET_GLOBAL_AND_INTERNATIONAL_REPORTS_PAGE = gql<{reports: Document[]; paging: number}, {reportsLimit: number}>`
  query ($reportsLimit: Int!) {
    reports: edu2030_documents(
      limit: $reportsLimit
      filter: {status: {_eq: "published"}, type: {_eq: 1}}
      sort: ["-date_created"]
    ) {
      id
      title
      image {
        id
        filename: filename_download
        title
      }
      file {
        id
        filename_download
        title
        type
        filesize
      }
      url
    }
    paging: edu2030_documents_aggregated(filter: {status: {_eq: "published"}, type: {_eq: 1}}) {
      count {
        id
      }
    }
  }
`;

export const LIST_GLOBAL_AND_INTERNATIONAL_REPORTS_BY_PAGE = gql<
  {reports: Document[]; paging: number},
  {reportsLimit: number; page: number}
>`
  query ($reportsLimit: Int!, $page: Int!) {
    reports: edu2030_documents(
      limit: $reportsLimit
      filter: {status: {_eq: "published"}, type: {_eq: 1}}
      sort: ["-date_created"]
      page: $page
    ) {
      id
      title
      image {
        id
        filename: filename_download
        title
      }
      file {
        id
        filename_download
        title
        type
        filesize
      }
      url
    }
    paging: edu2030_documents_aggregated(filter: {status: {_eq: "published"}, type: {_eq: 1}}) {
      count {
        id
      }
    }
  }
`;
