import {Component} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {Breadcrumb} from "src/app/interfaces/breadcrumb";
import {Hero} from "src/app/interfaces/hero";

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"],
})
export class PageComponent {
  title!: string;
  hero!: Hero;
  breadcrumbs!: Breadcrumb[];
  hideBreadcrumbs!: boolean;

  constructor(private titleService: Title, private route: ActivatedRoute) {}
}
