import {Component, OnInit} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {Document} from "src/app/interfaces/landing-page";
import { PageData } from "src/app/interfaces/page-data";
import {CMSService} from "src/app/services/cms.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-reports",
  templateUrl: "./global-and-international-reports.component.html",
  styleUrls: ["./global-and-international-reports.component.scss"],
})
export class InternationalReportsComponent implements OnInit {
  reports!: Document[];
  constructor(private titleService: Title, private route: ActivatedRoute, private cmsService: CMSService) {
    this.setPageTitle()
  }

  loading!: boolean;
  page = 0;
  unsubscribe$ = new ReplaySubject(1);

  totalDocuments!: number;
  reportsLimit = environment.limit.documentsLimit;
  paginationButtonsNumber!: number;

  ngOnInit(): void {
    this.reports = this.route.snapshot.data["page"].reports;
    this.totalDocuments = this.route.snapshot.data["page"].paging[0].count.id;
    this.paginationButtonsNumber = Math.ceil(this.totalDocuments / this.reportsLimit);
  }

  setPageTitle() {
    let route = this.route;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const {title} = route.snapshot.data["page"] as PageData;
    if (title) {
      this.titleService.setTitle(`${title} | التعليم 2030`);
    } else {
      this.titleService.setTitle(`تقارير إقليمية ودولية | التعليم 2030`);
    }
  }

  showItemsByPage(page: number) {
    const listItems = document.getElementsByClassName("page-item");
    for (let i = 0; i < listItems.length; i++) {
      const item = listItems[i];
      if (page === i + 1) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    }
    this.cmsService
      .getReportsByPage(page)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result: any) => {
          this.totalDocuments = result.paging[0].count.id;
          this.reports = result.reports;
          this.loading = false;
          window.scrollTo(0, 0);
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
