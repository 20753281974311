import {Component, Input, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {AppYoutubeZoomComponent} from "src/app/components/shared/app-youtube-zoom/app-youtube-zoom.component";
import {Education2030Video} from "src/app/interfaces/landing-page";
@Component({
  selector: "app-landing-video",
  templateUrl: "./landing-video.component.html",
  styleUrls: ["./landing-video.component.scss"],
})
export class LandingVideoComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  @Input() videos!: Education2030Video[];
  loading!: boolean;

  ngOnInit(): void {
    if (this.videos) this.loading = false;
  }
  getThumbnailURL(url: string): string {
    const normalizedURL = new URL(url);
    if (["www.youtube.com", "youtube.com", "youtu.be"].indexOf(normalizedURL.host) !== -1) {
      let videoId: string | undefined;
      const v = normalizedURL.searchParams.get("v");
      if (v && v.match(/^[\w-]{10,12}$/)) {
        videoId = v;
      } else {
        const match = /^(?:\/embed)?\/([\w-]{10,12})$/.exec(normalizedURL.pathname);
        if (match) {
          videoId = match[1];
        }
      }

      if (videoId) {
        return `http://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      }
    }
    return "http://img.youtube.com/vi/0/hqdefault.jpg";
  }

  openZoomDialog(video: Education2030Video): void {
    this.dialog.open(AppYoutubeZoomComponent, {
      data: {youtubeVideoUrl: video.url},
    });
  }
}
