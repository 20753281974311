import {gql} from "apollo-angular";
import {Channel} from "../interfaces/landing-page";

export const GET_CHANNELS_PAGE = gql<{channels: Channel[]; paging: number}, {channelsLimit: number}>`
  query ($channelsLimit: Int!) {
    channels: edu2030_channels(limit: $channelsLimit, filter: {status: {_eq: "published"}}, sort: ["-date_created"]) {
      id
      name
      logo {
        id
        filename: filename_download
        title
      }
      url
    }
    paging: edu2030_channels_aggregated(filter: {_and: [{status: {_eq: "published"}}]}) {
      count {
        id
      }
    }
  }
`;

export const LIST_CHANNELS_BY_PAGE = gql<{channels: Channel[]; paging: number}, {channelsLimit: number; page: number}>`
  query ($channelsLimit: Int!, $page: Int!) {
    channels: edu2030_channels(
      limit: $channelsLimit
      filter: {status: {_eq: "published"}}
      sort: ["-date_created"]
      page: $page
    ) {
      id
      name
      logo {
        id
        filename: filename_download
        title
      }
      url
    }
    paging: edu2030_channels_aggregated(filter: {_and: [{status: {_eq: "published"}}]}) {
      count {
        id
      }
    }
  }
`;
