<section id="reports-section">
  <h4 class="title">تقارير ودراسات التعليم 2030</h4>

  <div class="container">
    <div flickity [flickityConfig]="flickityConfig" class="slider circle-slider-arrows mt-5">
      <div *ngFor="let document of documents" class="report-card">
        <div class="card">
          <img
            [src]="document.image | imageLink"
            class="card-img-top img-fluid"
            [alt]="document.image.title"
            style="height: 420px"
          />
          <div class="card-body text-center">
            <h6 class="text-dark fw-bold mt-3 line-clamp-2" style="height: 39px">{{ document.title }}</h6>

            <a
              [href]="document.file | fileLink"
              target="_blank"
              class="btn btn-outline-very-light-gray text-dark px-4 smaller mt-3 mb-3"
              style="margin-left: 1rem"
            >
              تحميل التقرير
              <i class="bi bi-download ms-2"></i>
            </a>

            <a
              *ngIf="document.url"
              [href]="document.url"
              target="_blank"
              class="btn btn-outline-very-light-gray text-dark px-4 smaller mt-3 mb-3"
            >
              تحميل العروض
              <i class="bi bi-download ms-2"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center mt-3">
    <a routerLink="/reports" class="btn btn-outline-very-light-gray px-4 smaller">
      المزيد
      <i class="fa-solid fa-angles-left ms-2"></i>
    </a>
  </div>
</section>
