import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {Education2030Video} from "src/app/interfaces/landing-page";
import {CMSService} from "src/app/services/cms.service";
import {environment} from "src/environments/environment";
import {AppYoutubeZoomComponent} from "../../shared/app-youtube-zoom/app-youtube-zoom.component";
import {MatDialog} from "@angular/material/dialog";
import {PageData} from "src/app/interfaces/page-data";
import {Title} from "@angular/platform-browser";
@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"],
})
export class VideoComponent implements OnInit, OnDestroy {
  videos!: Education2030Video[];
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private cmsService: CMSService,
    public dialog: MatDialog,
  ) {}

  loading!: boolean;
  page = 0;
  unsubscribe$ = new ReplaySubject(1);

  totalDocuments!: number;
  videosLimit = environment.limit.videosLimit;
  paginationButtonsNumber!: number;

  ngOnInit(): void {
    this.setPageTitle();
    this.videos = this.route.snapshot.data["page"].videos;
    this.totalDocuments = this.route.snapshot.data["page"].paging[0].count.id;
    this.paginationButtonsNumber = Math.ceil(this.totalDocuments / this.videosLimit);
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  showItemsByPage(page: number) {
    const listItems = document.getElementsByClassName("page-item");
    for (let i = 0; i < listItems.length; i++) {
      const item = listItems[i];
      if (page === i + 1) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    }
    this.cmsService
      .getVideosByPage(page)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result: any) => {
          this.totalDocuments = result.paging[0].count.id;
          this.loading = false;
          this.videos = result.videos;
          window.scrollTo(0, 0);
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  getThumbnailURL(url: string): string {
    const normalizedURL = new URL(url);
    if (["www.youtube.com", "youtube.com", "youtu.be"].indexOf(normalizedURL.host) !== -1) {
      let videoId: string | undefined;
      const v = normalizedURL.searchParams.get("v");
      if (v && v.match(/^[\w-]{10,12}$/)) {
        videoId = v;
      } else {
        const match = /^(?:\/embed)?\/([\w-]{10,12})$/.exec(normalizedURL.pathname);
        if (match) {
          videoId = match[1];
        }
      }

      if (videoId) {
        return `http://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      }
    }
    return "http://img.youtube.com/vi/0/hqdefault.jpg";
  }
  openZoomDialog(video: Education2030Video): void {
    this.dialog.open(AppYoutubeZoomComponent, {
      data: {youtubeVideoUrl: video.url},
    });
  }

  setPageTitle() {
    let route = this.route;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const {title} = route.snapshot.data["page"] as PageData;
    if (title) {
      this.titleService.setTitle(`${title} | التعليم 2030`);
    } else {
      this.titleService.setTitle(`مكتبة الفيديو | التعليم 2030`);
    }
  }
}
