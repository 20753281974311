<section id="purposes-section">
  <h4 class="title">الغايات العشرة للتعليم 2030 المتفق عليها دولياً</h4>

  <p [innerHTML]="educationGoals" style="text-align: justify; margin-inline: 24%"></p>

  <div class="container">
    <div flickity [flickityConfig]="flickityConfig" class="slider mt-5 px-3 px-md-0 circle-slider-arrows">
      <a *ngFor="let goal of goals" class="goals-card" style="height: 300px" target="_blank">
        <div
          class="card h-100 rounded-3 border-transparent hover-border-primary overflow-hidden position-relative bg-primary bg-opacity-10"
        >
          <a class="card-body p-4 cursor-pointer" [href]="goal.url" target="_blank">
            <img src="assets/images/purpose.png" alt="" class="mb-2" />
            <div class="card-title text-dark fw-bold opacity-75 h5">{{ goal.name }}</div>
            <p class="text-dark mb-0 ellipse-7">
              {{ goal.description }}
            </p>
          </a>
        </div>
      </a>
    </div>
  </div>
  <div class="text-center mt-5">
    <a routerLink="/goals" class="btn btn-outline-very-light-gray px-4 smaller">
      الغايات العشر
      <i class="fa-solid fa-angles-left ms-2"></i>
    </a>
  </div>
</section>
