import {gql} from "apollo-angular";
import {
  Activity,
  Goal,
  Education21Report,
  Education2030Video,
  LandingPage,
  Document,
  Channel,
} from "../interfaces/landing-page";

export const GET_LANDING_PAGE_DATA = gql<
  {
    page: LandingPage;
    goals: Goal[];
    activities: Activity[];
    education21: Education21Report[];
    videos: Education2030Video[];
    documents: Document[];
    channels: Channel[];
  },
  {
    goalsLimit: number;
    education21Limit: number;
    documentsLimit: number;
    channelsLimit: number;
  }
>`
  query ($goalsLimit: Int!, $education21Limit: Int!, $documentsLimit: Int!, $channelsLimit: Int!) {
    page: edu2030_home_page {
      heroSectionIntroText: title
      educationGoals: education_goals
      image1 {
        id
        filename: filename_download
        title
      }
      image2 {
        id
        filename: filename_download
        title
      }
      image3 {
        id
        filename: filename_download
        title
      }
      image4 {
        id
        filename: filename_download
        title
      }
      image5 {
        id
        filename: filename_download
        title
      }
    }
    goals: edu2030_goals(limit: $goalsLimit, filter: {status: {_eq: "published"}}, sort: ["order"]) {
      id
      name
      description
      order
      url
    }
    activities: edu2030_office_activities(limit: 8, filter: {status: {_eq: "published"}}, sort: ["-date_created"]) {
      id
      name
      type {
        id
        name
      }
      image {
        id
        filename: filename_download
        title
      }
      file {
        id
        filename_download
        title
        type
        filesize
      }
      activityUrl: activity_url
      youtubeVideoUrl: youtube_video_url
    }
    education21: edu2030_education21(
      limit: $education21Limit
      filter: {status: {_eq: "published"}}
      sort: ["-date_created"]
    ) {
      id
      title
      image {
        id
        filename: filename_download
        title
      }
      url
    }
    videos: edu2030_videos(
      filter: {_and: [{status: {_eq: "published"}}, {show_at_home: {_eq: "1"}}]}
      sort: ["-date_created"]
    ) {
      id
      title
      url
      showAtHome: show_at_home
    }

    documents: edu2030_documents(limit: $documentsLimit, filter: {status: {_eq: "published"}}, sort: ["-date_created"]) {
      id
      title
      image {
        id
        filename: filename_download
        title
      }
      file {
        id
        filename_download
        title
        type
        filesize
      }
      url
    }

    channels: edu2030_channels(limit: $channelsLimit, filter: {status: {_eq: "published"}}, sort: ["-date_created"]) {
      id
      name
      logo {
        id
        filename: filename_download
        title
      }
      url
    }
  }
`;
