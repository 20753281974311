import {Injectable} from "@angular/core";
import {Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {GET_EDUCATION_ACTIVITIES_PAGE} from "../queries/activities-page.query";

@Injectable({
  providedIn: "root",
})
export class ActivitiesPageResolver implements Resolve<unknown> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<unknown> {
    return this.apollo
      .query({
        query: GET_EDUCATION_ACTIVITIES_PAGE,
        variables: {
          activitiesLimit: environment.limit.activitiesLimit,
        },
      })
      .pipe(
        map((result): unknown => {
          return {
            activities: result.data.activities,
            activitiesTypes: result.data.activitiesTypes,
            paging: result.data.paging,
          };
        }),
      );
  }
}
