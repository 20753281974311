<app-page>
  <page-header></page-header>
  <page-content>
    <app-hero pageTitle="تقارير إقليمية و دولية" heroImage="assets/images/reports-docs-hero.png"></app-hero>
    <div id="reports" class="my-5">
      <div class="container">
        <div class="row">
          <div *ngFor="let report of reports" class="col-xl-4 col-lg-6 col-sm-6 mb-4">
            <a>
              <div class="card">
                <img
                  [src]="report.image | imageLink"
                  class="card-img-top img-fluid"
                  [alt]="report.image.title"
                  style="height: 420px"
                />
                <div class="card-body text-center">
                  <h6 class="text-dark fw-bold mt-3" style="height: 39px">{{ report.title }}</h6>

                  <a
                    [href]="report.file | fileLink"
                    target="_blank"
                    class="btn btn-outline-very-light-gray text-dark px-4 smaller mt-3 mb-3"
                    style="margin-left: 1rem"
                  >
                    تحميل التقرير
                    <i class="bi bi-download ms-2"></i>
                  </a>

                  <a
                    *ngIf="report.url"
                    [href]="report.url"
                    target="_blank"
                    class="btn btn-outline-very-light-gray text-dark px-4 smaller mt-3 mb-3"
                  >
                    تحميل العروض
                    <i class="bi bi-download ms-2"></i>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="mt-5">
          <nav *ngIf="paginationButtonsNumber > 0">
            <ul class="pagination justify-content-center dark">
              <li
                *ngFor="let item of [].constructor(paginationButtonsNumber); index as i"
                (click)="showItemsByPage(i + 1)"
                class="page-item"
                [ngClass]="{active: page === i}"
              >
                <a class="page-link">{{ i + 1 }}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div *ngIf="reports?.length === 0" class="no-data">لا توجد تقارير</div>
  </page-content>
  <page-footer></page-footer>
</app-page>
