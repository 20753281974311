<app-navbar></app-navbar>
<div id="home">
  <app-landing-hero [heroSectionIntroText]="meta.heroSectionIntroText" [heroImages]="heroImages"></app-landing-hero>
  <app-landing-goals [goals]="goalsSlide" [educationGoals]="meta.educationGoals"></app-landing-goals>
  <app-landing-activities [activities]="activities"></app-landing-activities>
  <app-landing-video [videos]="videoSection"></app-landing-video>
  <app-landing-education21 [education21Cards]="education21Slider"></app-landing-education21>
  <app-landing-reports [documents]="documentsSection"></app-landing-reports>
  <app-landing-chanels [channels]="channelsSection"></app-landing-chanels>
</div>
<app-footer-links></app-footer-links>
