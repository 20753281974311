import {Injectable} from "@angular/core";
import {Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {GET_VIDEOS_PAGE} from "../queries/videos-page.query";

@Injectable({
  providedIn: "root",
})
export class VideosPageResolver implements Resolve<unknown> {
  constructor(private apollo: Apollo) {}
  resolve(): Observable<unknown> {
    return this.apollo
      .query({
        query: GET_VIDEOS_PAGE,
        variables: {
          videosLimit: environment.limit.videosLimit,
        },
      })
      .pipe(
        map((result): unknown => {
          return {
            videos: result.data.videos,
            paging: result.data.paging,
          };
        }),
      );
  }
}
