<div id="home-hero" style="background-image: url('assets/images/hero-pattern.png')">
  <div class="container position-relative h-100 py-5">
    <img alt="..." src="assets/images/edu2030-shadow.png" class="outlined-text text-1" draggable="false" />
    <img alt="..." src="assets/images/office-shadow.png" class="outlined-text text-2" draggable="false" />
    <div class="row h-100 align-items-center pb-5">
      <div class="col-lg-6 mb-5 mb-md-0 d-flex flex-column justify-content-md-center align-items-start">
        <div data-aos="fade-up" class="title h6 fw-bold" data-aos="fade-up">التعليم 2030</div>
        <div data-aos="fade-up" class="subtitle h1 fw-bold" data-aos="fade-up">{{ heroSectionIntroText }}</div>
      </div>
      <div class="col px-5 px-lg-3">
        <div class="hero-image" dir="ltr">
          <div class="artwork">
            <img alt="..." class="artwork-1" src="assets/images/hero-artwork-1.png" />
            <img alt="..." class="artwork-2" src="assets/images/hero-artwork-2.png" />
          </div>

          <div class="thumbnails h-100 w-100">
            <img
              *ngFor="let img of heroImages; index as i"
              [alt]="img.title"
              [src]="img | imageLink"
              data-aos="flip-up"
              attr.data-aos-delay="{{ 200 * i + 200 }}"
              attr.data-aos-duration="{{ 1000 }}"
              class="thumbnail thumbnail-{{ i + 1 }}"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="arrows text-center mt-3">
      <a
        href="./#purposes-section"
        class="d-inline-flex flex-column align-items-center justify-content-center"
        style="color: inherit"
        target="_self"
      >
        <i *ngFor="let item of [].constructor(2)" class="fa-solid fa-angles-down"></i>
      </a>
    </div>
  </div>
</div>
