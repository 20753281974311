import {Component, Input} from "@angular/core";
import {ImageFile} from "src/app/interfaces/image-file";

@Component({
  selector: "app-landing-hero",
  templateUrl: "./landing-hero.component.html",
  styleUrls: ["./landing-hero.component.scss"],
})
export class LandingHeroComponent {
  @Input() heroSectionIntroText!: string;
  @Input() heroImages!: ImageFile[];
}
