<section id="channels-section">
  <h4 class="title">القنوات التعليمية على اليوتيوب</h4>

  <div class="container">
    <div flickity [flickityConfig]="flickityConfig" class="slider circle-slider-arrows mt-5 px-4">
      <div *ngFor="let channel of channels" class="card channel-card hover-border-primary">
        <a [href]="channel.url" target="_blank" class="card-body">
          <img
            class="img-fluid d-block mx-auto"
            [src]="channel.logo | imageLink"
            [alt]="channel.logo.title"
            style="height: 80px"
          />
          <h6 style="height: 40px; font-size: small" class="text-center text-dark mt-3 mb-0 ellipse-2 fw-bold">
            {{ channel.name }}
          </h6>
        </a>
      </div>
    </div>
  </div>
</section>
