import {Component, OnInit} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {Goal} from "src/app/interfaces/landing-page";
import {PageData} from "src/app/interfaces/page-data";

@Component({
  selector: "app-goals",
  templateUrl: "./goals.component.html",
  styleUrls: ["./goals.component.scss"],
})
export class GoalsComponent implements OnInit {
  goals!: Goal[];

  constructor(private route: ActivatedRoute, private titleService: Title) {
    this.setPageTitle();
  }

  ngOnInit(): void {
    this.goals = this.route.snapshot.data["page"] as Goal[];
  }

  setPageTitle() {
    let route = this.route;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const {title} = route.snapshot.data["page"] as PageData;
    if (title) {
      this.titleService.setTitle(`${title} | التعليم 2030`);
    } else {
      this.titleService.setTitle(`غايات التعليم | التعليم 2030`);
    }
  }
}
