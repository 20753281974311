import {gql} from "apollo-angular";
import {Education2030Video} from "../interfaces/landing-page";

export const GET_VIDEOS_PAGE = gql<{videos: Education2030Video[]; paging: number}, {videosLimit: number}>`
  query ($videosLimit: Int!) {
    videos: edu2030_videos(filter: {_and: [{status: {_eq: "published"}}]}, sort: ["-date_created"], limit: $videosLimit) {
      id
      title
      url
      showAtHome: show_at_home
    }
    paging: edu2030_videos_aggregated(filter: {_and: [{status: {_eq: "published"}}]}) {
      count {
        id
      }
    }
  }
`;

export const LIST_VIDEOS_BY_PAGE = gql<{videos: Education2030Video[]; paging: number}, {videosLimit: number; page: number}>`
  query ($videosLimit: Int!, $page: Int!) {
    videos: edu2030_videos(
      filter: {_and: [{status: {_eq: "published"}}]}
      sort: ["-date_created"]
      page: $page
      limit: $videosLimit
    ) {
      id
      title
      url
      showAtHome: show_at_home
    }
    paging: edu2030_videos_aggregated(filter: {_and: [{status: {_eq: "published"}}]}) {
      count {
        id
      }
    }
  }
`;
