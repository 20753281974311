<section id="education-activities-section">
  <h4 class="title">أنشطة وفعاليات التعليم 2030</h4>
  <p class="subtitle">أبرز الفعاليات والورش والندوات والأحدات الإقليمية الخاصة بالتعليم 2030</p>

  <div class="container">
    <div flickity [flickityConfig]="flickityConfig" class="slider mt-5 circle-slider-arrows">
      <div *ngFor="let activity of activities" class="education-activity-card" (click)="handleClick(activity)">
        <div
          class="card overflow-hidden position-relative item"
          [attr.data-src]="activity.activityUrl"
          [attr.data-sub-html]="activity.name"
        >
          <img
            class="img-fluid d-block w-100"
            style="height: 400px"
            [src]="activity.image | imageLink"
            [alt]="activity.image.title"
          />

          <span class="activity-type text-white fw-bold bg-primary position-absolute px-3 py-2">
            {{ activity.type.name }}
          </span>
        </div>
        <h6 class="text-center text-dark fw-bold mt-3 mb-0 small ellipse-3 line-clamp-2">
          {{ activity.name }}
        </h6>
      </div>
    </div>
  </div>

  <div class="text-center mt-5">
    <a routerLink="activities" class="btn btn-outline-very-light-gray px-4">
      المزيد
      <i class="fa-solid fa-angles-left ms-2"></i>
    </a>
  </div>
</section>
