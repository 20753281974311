import {Injectable} from "@angular/core";
import {Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable, of} from "rxjs";
import {Goal} from "../interfaces/landing-page";
import {GET_GOALS_PAGE} from "../queries/goals-page.query";

@Injectable({
  providedIn: "root",
})
export class GoalsPageResolver implements Resolve<Goal[]> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<Goal[]> {
    return this.apollo
      .query({
        query: GET_GOALS_PAGE,
      })
      .pipe(
        map((result): Goal[] => {
          return result.data.goals;
        }),
      );
  }
}
