import {Component, Input} from "@angular/core";
import {Document} from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-reports",
  templateUrl: "./landing-reports.component.html",
  styleUrls: ["./landing-reports.component.scss"],
})
export class LandingInternationalReportsComponent {
  @Input() documents!: Document[];
  flickityConfig: Flickity.Options = {
    rightToLeft: true,
    cellAlign: "right",
    wrapAround: true,
    // contain: true,
    pageDots: false,
    autoPlay: true,
  };
}
