import {gql} from "apollo-angular";
import {Education21Report} from "../interfaces/landing-page";

export const GET_EDUCATION21_PAGE = gql<{education21: Education21Report[]; paging: number}, {education21Limit: number}>`
  query ($education21Limit: Int!) {
    education21: edu2030_education21(
      limit: $education21Limit
      filter: {status: {_eq: "published"}}
      sort: ["-date_created"]
    ) {
      id
      title
      image {
        id
        filename: filename_download
        title
      }
      url
    }
    paging: edu2030_education21_aggregated(filter: {_and: [{status: {_eq: "published"}}]}) {
      count {
        id
      }
    }
  }
`;

export const LIST_EDUCATION21_BY_PAGE = gql<
  {education21: Education21Report[]; paging: number},
  {education21Limit: number; page: number}
>`
  query ($education21Limit: Int!, $page: Int!) {
    education21: edu2030_education21(
      limit: $education21Limit
      filter: {status: {_eq: "published"}}
      sort: ["-date_created"]
      page: $page
    ) {
      id
      title
      image {
        id
        filename: filename_download
        title
      }
      url
    }
    paging: edu2030_education21_aggregated(filter: {_and: [{status: {_eq: "published"}}]}) {
      count {
        id
      }
    }
  }
`;
