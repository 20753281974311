import {Component, Input} from "@angular/core";
import {Goal} from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-goals",
  templateUrl: "./landing-goals.component.html",
  styleUrls: ["./landing-goals.component.scss"],
})
export class LandingGoalsComponent {
  @Input() goals!: Goal[];
  @Input() educationGoals!: string;
  flickityConfig: Flickity.Options = {
    rightToLeft: true,
    cellAlign: "right",
    wrapAround: true,
    // contain: true,
    pageDots: false,
    autoPlay: true,
  };
}
