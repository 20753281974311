import {Component, Input} from "@angular/core";
import {Hero} from "src/app/interfaces/hero";

@Component({
  selector: "app-hero",
  templateUrl: "./hero.component.html",
  styleUrls: ["./hero.component.scss"],
})
export class HeroComponent {
  @Input() data!: Hero;
  @Input() pageTitle!: string;
  @Input() heroImage!: string;
}
