import {gql} from "apollo-angular";
import {Goal} from "../interfaces/landing-page";

export const GET_GOALS_PAGE = gql<{goals: Goal[]}, void>`
  query {
    goals: edu2030_goals(filter: {status: {_eq: "published"}}, sort: ["order"]) {
      id
      name
      description
      order
      url
    }
  }
`;
