<app-page>
  <page-header></page-header>
  <page-content>
    <app-hero pageTitle="مكتبة الفيديو" heroImage="assets/images/videos-hero.png"></app-hero>
    <div id="video" class="my-5">
      <div class="container">
        <div class="row">
          <div *ngFor="let video of videos" class="col-lg-6 col-sm-12 mb-5" (click)="openZoomDialog(video)">
            <div class="item" [attr.data-src]="video.url" [attr.data-sub-html]="video.title">
              <div class="video-card">
                <div class="card rounded-0 position-relative thumbnail-container">
                  <img
                    class="img-fluid contain d-block w-100 thumbnail-image"
                    [src]="getThumbnailURL(video.url)"
                    [alt]="video.title"
                  />
                  <img src="assets/images/youtube-icon.png" alt="youtube-icon" class="youtube-icon" />
                </div>
                <h6 class="text-center text-dark fw-bold mt-3 mb-0 mx-3">
                  {{ video.title }}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="loading" class="d-flex align-items-center justify-content-center bg-light">
          <div class="spinner"></div>
        </div>

        <div class="mt-5">
          <nav *ngIf="paginationButtonsNumber > 0">
            <ul class="pagination justify-content-center dark">
              <li
                *ngFor="let item of [].constructor(paginationButtonsNumber); index as i"
                (click)="showItemsByPage(i + 1)"
                class="page-item"
                [ngClass]="{active: page === i}"
              >
                <a class="page-link">{{ i + 1 }}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div *ngIf="videos?.length === 0" class="no-data">لا توجد فيديوهات</div>
  </page-content>
  <page-footer></page-footer>
</app-page>
