import {Component, OnInit} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {Education21Report} from "src/app/interfaces/landing-page";
import {PageData} from "src/app/interfaces/page-data";
import {CMSService} from "src/app/services/cms.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-education21",
  templateUrl: "./education21.component.html",
  styleUrls: ["./education21.component.scss"],
})
export class Education21Component implements OnInit {
  education21!: Education21Report[];
  constructor(private route: ActivatedRoute, private cmsService: CMSService, private titleService: Title) {
    this.setPageTitle();
  }

  loading!: boolean;
  page = 0;
  unsubscribe$ = new ReplaySubject(1);

  totalDocuments!: number;
  education21Limit = environment.limit.education21Limit;
  paginationButtonsNumber!: number;

  ngOnInit(): void {
    this.education21 = this.route.snapshot.data["page"].education21;
    this.totalDocuments = this.route.snapshot.data["page"].paging[0].count.id;
    this.paginationButtonsNumber = Math.ceil(this.totalDocuments / this.education21Limit);
  }

  showItemsByPage(page: number) {
    const listItems = document.getElementsByClassName("page-item");
    for (let i = 0; i < listItems.length; i++) {
      const item = listItems[i];
      if (page === i + 1) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    }
    this.cmsService
      .getEducation21ByPage(page)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result: any) => {
          this.totalDocuments = result.paging[0].count.id;
          this.education21 = result.education21;
          this.loading = false;
          window.scrollTo(0, 0);
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  setPageTitle() {
    let route = this.route;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const {title} = route.snapshot.data["page"] as PageData;
    if (title) {
      this.titleService.setTitle(`${title} | التربية 21`);
    } else {
      this.titleService.setTitle(`التربية 21 | التعليم 2030`);
    }
  }
}
