import {Component, ElementRef, ViewChild, HostListener} from "@angular/core";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-footer-links",
  templateUrl: "./footer-links.component.html",
  styleUrls: ["./footer-links.component.scss"],
})
export class FooterLinksComponent {
  SCROLL_OFFSET = 300;

  contact = {
    email: "abegs@abegs.org",
    location: "المملكة العربية السعودية ،الرياض، طريق الملك سعود، الوزارات",
    phone: "00966114800555",
  };
  portalURI = environment.portalURI;
  @ViewChild("scrollTopBtn") scrollTopButtonRef!: ElementRef<HTMLButtonElement>;

  //TODO Uncomment
  // portalUrlContactUs = `${environment.portalURI}/contact-us`;
  portalUrlContactUs = `#`;
  footerLinks = [
    {
      title: "غايات التعليم 2030",
      href: "/goals",
    },
    {
      title: "أنشطة وفعاليات التعليم 2030 ",
      href: "/activities",
    },
    {
      title: "تقارير إقليمية و دولية",
      href: "/reports",
    },
    {
      title: "تقارير المكتب",
      href: `${environment.portalURI}/reports`,
      external: true,
    },
    {
      title: "التربية  21",
      href: "/education21",
    },
    {
      title: "مكتبة الفيديو",
      href: "/videos",
    },
  ];

  @HostListener("window:scroll")
  onWindowScroll() {
    if (!this.scrollTopButtonRef) return;

    if (window.scrollY > this.SCROLL_OFFSET) {
      this.scrollTopButtonRef.nativeElement.classList.add("shown");
    } else {
      this.scrollTopButtonRef.nativeElement.classList.remove("shown");
    }
  }
}
