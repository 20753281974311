import {Component, Input} from "@angular/core";
import {Channel} from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-chanels",
  templateUrl: "./landing-chanels.component.html",
  styleUrls: ["./landing-chanels.component.scss"],
})
export class LandingChanelsComponent {
  @Input() channels!: Channel[];
  flickityConfig: Flickity.Options = {
    wrapAround: true,
    pageDots: false,
    rightToLeft: true,
    cellAlign: "right",
    autoPlay: true,
  };
}
