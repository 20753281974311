<nav
  id="navbar_top"
  [ngClass]="{
    'navbar-border': heightFlag,
    'fixed-top shadow fade-in-down fast': !heightFlag
  }"
  class="navbar navbar-expand-lg bg-white py-1 py-md-0"
>
  <div class="container">
    <a class="navbar-brand py-0 mx-auto ps-5 ps-md-0" routerLink="/" target="_self">
      <!-- Small screens -->
      <img class="d-lg-none logo-sm" src="assets/images/abegs.svg" height="80px" alt="Logo" />

      <!-- Large screens -->
      <img class="d-none d-lg-block" src="assets/images/abegs-vertical.svg" height="82px" alt="Logo" />
    </a>

    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#mainOffcanvas"
      aria-label="sidebar"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="mainOffcanvas" aria-labelledby="mainOffcanvasLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="mainOffcanvasLabel"></h5>
        <button type="button" class="btn btn-link text-light fs-4" data-bs-dismiss="offcanvas" aria-label="Close">
          <i class="bi bi-x-lg"></i>
        </button>
      </div>

      <div class="offcanvas-body" id="navbar-top">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item mx-2 my-lg-2">
            <a class="d-none d-lg-inline-flex nav-link small" [href]="portalURI" target="_blank">بوابة المكتب الرئيسية</a>
          </li>
          <li *ngFor="let link of navbarLinks" class="nav-item mx-2 my-lg-2" [ngClass]="{dropdown: link.menuItems}">
            <a
              *ngIf="link.menuItems"
              target="_self"
              class="d-none d-lg-inline-flex nav-link small"
              [ngClass]="{
                'dropdown-toggle': link.menuItems,
                'fw-bold':
                  link.menuItems[0].href.includes(currentRoute) ||
                  link.menuItems[1].href.includes(currentRoute) ||
                  link.menuItems[2].href.includes(currentRoute)
              }"
              [routerLink]="link.href"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ link.title }}
            </a>

            <a
              *ngIf="!link.menuItems"
              target="_self"
              class="d-none d-lg-inline-flex nav-link small"
              [ngClass]="{'fw-bold': link.href.includes(currentRoute)}"
              [routerLink]="link.href"
            >
              {{ link.title }}
            </a>

            <!-- Offcanvas -->
            <a
              *ngIf="link.menuItems"
              target="_self"
              class="d-lg-none d-flex justify-content-between nav-link small"
              [ngClass]="{'dropdown-toggle': link.menuItems}"
              role="button"
              data-bs-toggle="offcanvas"
              attr.data-bs-target="#offcanvas{{ link.id }}"
              attr.aria-controls="offcanvas{{ link.id }}"
            >
              {{ link.title }}
            </a>
            <a
              *ngIf="!link.menuItems"
              target="_self"
              class="d-lg-none d-flex justify-content-between nav-link small"
              [ngClass]="{'fw-bold': link.href.includes(currentRoute)}"
              [routerLink]="link.href"
            >
              {{ link.title }}
            </a>
            <!-- Dropdown -->
            <ul *ngIf="link.menuItems" class="dropdown-menu animate slideIn">
              <li *ngFor="let item of link.menuItems">
                <a *ngIf="item.external" target="_blank" class="dropdown-item small" [href]="item.href">
                  {{ item.title }}
                </a>
                <a *ngIf="!item.external" target="_self" class="dropdown-item small" [routerLink]="item.href">
                  {{ item.title }}
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item mx-2 my-2">
            <a class="nav-link" target="_blank" [href]="portalUrlContactUs">تواصل معنا</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<div
  *ngFor="let link of navSubmenuItems"
  class="offcanvas offcanvas-start"
  tabindex="-1"
  id="offcanvasreports"
  aria-labelledby="offcanvasreportsLabel"
>
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasreportsLabel">{{ link.title }}</h5>
    <button
      type="button"
      class="btn btn-link text-light fs-4"
      data-bs-toggle="offcanvas"
      data-bs-target="#mainOffcanvas"
      aria-controls="mainOffcanvas"
      aria-label="Close"
    >
      <i class="bi bi-x-lg"></i>
    </button>
  </div>
  <div class="offcanvas-body">
    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
      <li *ngFor="let navLink of navSubmenuItems" class="nav-item mx-2 my-lg-2">
        <a *ngIf="navLink.external" target="_blank" class="nav-link small" [href]="navLink.href">{{ navLink.title }}</a>
        <a *ngIf="!navLink.external" target="_self" class="nav-link small" [routerLink]="navLink.href">
          {{ navLink.title }}
        </a>
      </li>
    </ul>
  </div>
</div>
