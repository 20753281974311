import {gql} from "apollo-angular";
import {Activity} from "../interfaces/landing-page";

export const GET_EDUCATION_ACTIVITIES_PAGE = gql<
  {activities: Activity[]; activitiesTypes: {name: string}; paging: number},
  {activitiesLimit: number}
>`
  query ($activitiesLimit: Int!) {
    activities: edu2030_office_activities(
      limit: $activitiesLimit
      filter: {status: {_eq: "published"}}
      sort: ["-date_created"]
    ) {
      id
      name
      type {
        id
        name
      }
      image {
        id
        filename: filename_download
        title
      }
      file {
        id
        filename_download
        title
        type
        filesize
      }
      activityUrl: activity_url
      youtubeVideoUrl: youtube_video_url
    }
    activitiesTypes: edu2030_activity_types {
      name
    }
    paging: edu2030_office_activities_aggregated(filter: {_and: [{status: {_eq: "published"}}]}) {
      count {
        id
      }
    }
  }
`;

export const LIST_ACTIVITY_BY_TYPE = gql<
  {activities: Activity[]; paging: number},
  {limit: number; page: number; type: string[]}
>`
  query ($limit: Int!, $page: Int!, $type: [String!]) {
    activities: edu2030_office_activities(
      filter: {_and: [{status: {_eq: "published"}}, {type: {name: {_in: $type}}}]}
      sort: ["-date_created"]
      limit: $limit
      page: $page
    ) {
      id
      name
      type {
        id
        name
      }
      image {
        id
        filename: filename_download
        title
      }
      file {
        id
        filename_download
        title
        type
        filesize
      }
      activityUrl: activity_url
      youtubeVideoUrl: youtube_video_url
    }
    paging: edu2030_office_activities_aggregated(
      filter: {_and: [{status: {_eq: "published"}}, {type: {name: {_in: $type}}}]}
    ) {
      count {
        id
      }
    }
  }
`;
