import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {
  Goal,
  Channel,
  Document,
  Education21Report,
  Activity,
  Education2030Video,
  LandingPage,
  LandingPageData,
} from "src/app/interfaces/landing-page";
import AOS from "aos";
import {DirectusFile} from "src/app/interfaces/directus-file";
import {ImageFile} from "src/app/interfaces/image-file";
import {Title} from "@angular/platform-browser";
import {PageData} from "src/app/interfaces/page-data";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  meta!: LandingPage;

  goalsSlide!: Goal[];
  activities!: Activity[];
  education21Slider!: Education21Report[];
  videoSection!: Education2030Video[];
  documentsSection!: Document[];
  channelsSection!: Channel[];
  heroVideo!: DirectusFile | null;
  heroImages!: ImageFile[];
  constructor(private route: ActivatedRoute, private titleService: Title) {
    this.setPageTitle();
  }

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as LandingPageData;
    this.meta = meta;
    this.heroImages = [meta.image1, meta.image2, meta.image3, meta.image4, meta.image5];
    this.goalsSlide = page.data.goals;
    this.channelsSection = page.data.channels;
    this.documentsSection = page.data.documents;
    this.education21Slider = page.data.education21;
    this.activities = page.data.activities;
    this.videoSection = page.data.videos;
    AOS.init({
      // disable: "mobile"
      once: true,
    });
    AOS.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
  }

  setPageTitle() {
    let route = this.route;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const {title} = route.snapshot.data["page"] as PageData;
    if (title) {
      this.titleService.setTitle(`${title} | التعليم 2030`);
    } else {
      this.titleService.setTitle(`التعليم 2030`);
    }
  }
}
