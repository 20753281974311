import {Injectable} from "@angular/core";
import {Apollo} from "apollo-angular";
import {Observable, map} from "rxjs";
import {LIST_ACTIVITY_BY_TYPE} from "../queries/activities-page.query";
import {environment} from "src/environments/environment";
import {LIST_EDUCATION21_BY_PAGE} from "../queries/education21-page.query";
import {LIST_GLOBAL_AND_INTERNATIONAL_REPORTS_BY_PAGE} from "../queries/global-and-international-reports-page.query";
import {LIST_CHANNELS_BY_PAGE} from "../queries/channels-page.query";
import {LIST_VIDEOS_BY_PAGE} from "../queries/videos-page.query";

@Injectable({
  providedIn: "root",
})
export class CMSService {
  constructor(private apollo: Apollo) {}

  getActivitiesByType(type: string[], page: number): Observable<unknown> {
    return this.apollo
      .query({
        query: LIST_ACTIVITY_BY_TYPE,
        variables: {
          limit: environment.limit.activitiesLimit,
          page: page,
          type: type,
        },
      })
      .pipe(
        map((result): unknown => {
          return {activities: result.data.activities, paging: result.data.paging};
        }),
      );
  }

  getEducation21ByPage(page: number): Observable<unknown> {
    return this.apollo
      .query({
        query: LIST_EDUCATION21_BY_PAGE,
        variables: {
          education21Limit: environment.limit.education21Limit,
          page: page,
        },
      })
      .pipe(
        map((result): unknown => {
          return {education21: result.data.education21, paging: result.data.paging};
        }),
      );
  }

  getReportsByPage(page: number): Observable<unknown> {
    return this.apollo
      .query({
        query: LIST_GLOBAL_AND_INTERNATIONAL_REPORTS_BY_PAGE,
        variables: {
          reportsLimit: environment.limit.documentsLimit,
          page: page,
        },
      })
      .pipe(
        map((result): unknown => {
          return {reports: result.data.reports, paging: result.data.paging};
        }),
      );
  }

  getChannelsByPage(page: number): Observable<unknown> {
    return this.apollo
      .query({
        query: LIST_CHANNELS_BY_PAGE,
        variables: {
          channelsLimit: environment.limit.channelsLimit,
          page: page,
        },
      })
      .pipe(
        map((result): unknown => {
          return {channels: result.data.channels, paging: result.data.paging};
        }),
      );
  }

  getVideosByPage(page: number): Observable<unknown> {
    return this.apollo
      .query({
        query: LIST_VIDEOS_BY_PAGE,
        variables: {
          videosLimit: environment.limit.videosLimit,
          page: page,
        },
      })
      .pipe(
        map((result): unknown => {
          return {videos: result.data.videos, paging: result.data.paging};
        }),
      );
  }
}
