import {Component, Input} from "@angular/core";

import {Activity} from "src/app/interfaces/landing-page";

import {MatDialog} from "@angular/material/dialog";
import {AppYoutubeZoomComponent} from "src/app/components/shared/app-youtube-zoom/app-youtube-zoom.component";
import {environment} from "src/environments/environment";
@Component({
  selector: "app-landing-activities",
  templateUrl: "./landing-activities.component.html",
  styleUrls: ["./landing-activities.component.scss"],
})
export class LandingActivitiesComponent {
  constructor(public dialog: MatDialog) {}

  @Input() activities!: Activity[];
  flickityConfig: Flickity.Options = {
    rightToLeft: true,
    cellAlign: "right",
    wrapAround: true,
    contain: true,
    pageDots: false,
    autoPlay: true,
  };

  handleClick(activity: Activity) {
    if (activity.activityUrl) {
      window.location.href = activity.activityUrl;
      return;
    }
    if (activity.youtubeVideoUrl) {
      this.openZoomDialog(activity.youtubeVideoUrl);
      return;
    }
    if (activity.file) {
      window.location.href = `${environment.assetsURI}/${activity.file.id}`;
    }
  }

  openZoomDialog(youtubeVideoUrl): void {
    this.dialog.open(AppYoutubeZoomComponent, {
      data: {youtubeVideoUrl},
    });
  }
}
