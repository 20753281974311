import {Component, Inject} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {environment} from "src/environments/environment";
@Component({
  selector: "app-app-youtube-zoom",
  templateUrl: "./app-youtube-zoom.component.html",
  styleUrls: ["./app-youtube-zoom.component.scss"],
})
export class AppYoutubeZoomComponent {
  constructor(public dialogRef: MatDialogRef<AppYoutubeZoomComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  assetsURI = environment.assetsURI;

  closeDialog(): void {
    this.dialogRef.close();
  }

  get videoId(): string {
    try {
      if (!this.data.youtubeVideoUrl) return "";
      const url = new URL(this.data.youtubeVideoUrl);
      return url.searchParams.get("v") ?? "";
    } catch (error) {
      return "";
    }
  }
}
