<app-page>
  <page-header></page-header>
  <page-content>
    <app-hero pageTitle="التربية 21" heroImage="assets/images/education21-hero.png"></app-hero>

    <div id="education21" class="my-5">
      <div class="container">
        <div class="row">
          <div *ngFor="let itemOfEducation21 of education21" class="col-xl-4 col-lg-6 col-sm-6 mb-5">
            <a [href]="itemOfEducation21.url" target="_blank">
              <div class="education21-card">
                <div class="card rounded-0">
                  <img
                    style="height: 400px"
                    class="img-fluid d-block w-100"
                    [src]="itemOfEducation21.image | imageLink"
                    [alt]="itemOfEducation21.image.title"
                  />
                </div>
                <h6 class="text-center text-dark fw-bold mt-3 mb-0">
                  {{ itemOfEducation21.title }}
                </h6>
              </div>
            </a>
          </div>
        </div>

        <div class="mt-5">
          <nav *ngIf="paginationButtonsNumber > 0">
            <ul class="pagination justify-content-center dark">
              <li
                *ngFor="let item of [].constructor(paginationButtonsNumber); index as i"
                (click)="showItemsByPage(i + 1)"
                class="page-item"
                [ngClass]="{active: page === i}"
              >
                <a class="page-link">{{ i + 1 }}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div *ngIf="education21?.length === 0" class="no-data">لا توجد بيانات</div>
  </page-content>
  <page-footer></page-footer>
</app-page>
