<section id="education21-section">
  <div class="container">
    <div class="row">
      <div class="col-md-3 d-flex align-items-center justify-content-center justify-content-md-start mb-5 mb-md-0">
        <div class="text-center">
          <img src="assets/images/unesco.png" alt="" />
          <h5 class="fw-bold">التربية 21</h5>
          <p class="">إعداد نشرة المتعلقة بالينسكو</p>
        </div>
      </div>
      <div class="col">
        <div flickity [flickityConfig]="flickityConfig" class="slider mb-5">
          <a
            [href]="education21Card.url"
            target="_blank"
            *ngFor="let education21Card of education21Cards"
            class="education21-card"
          >
            <div class="card rounded-0">
              <img
                style="height: 340px"
                class="img-fluid d-block w-100"
                [src]="education21Card.image | imageLink"
                [alt]="education21Card.image.title"
              />
            </div>
            <h6 class="education21-card-title text-center text-dark fw-bold mt-3 mb-0">
              {{ education21Card.title }}
            </h6>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
