import {Component, Input} from "@angular/core";
import {Education21Report} from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-education21",
  templateUrl: "./landing-education21.component.html",
  styleUrls: ["./landing-education21.component.scss"],
})
export class LandingEducation21Component {
  @Input() education21Cards!: Education21Report[];
  flickityConfig: Flickity.Options = {
    rightToLeft: true,
    cellAlign: "right",
    wrapAround: true,
    pageDots: true,
    contain: true,
    groupCells: true,
    prevNextButtons: true,
    autoPlay: true,
  };
}
